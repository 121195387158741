// Imports themes and global styles
import './src/shared/Styles/index.scss';

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  const pagesToSkipScroll = ['/solutions'];

  if (prevRouterProps) {
    const { pathname } = routerProps.location;
    const prevPathname = prevRouterProps.location.pathname;

    const pathSegments = pathname.split('/').filter(Boolean);
    const prevPathSegments = prevPathname.split('/').filter(Boolean);

    if (pagesToSkipScroll.some((page) => pathname.startsWith(page))) {
      if (pathSegments[1] === prevPathSegments[1]) {
        return false;
      }
      return true;
    }
  }

  return true; // allow scroll to top for other pages
};
